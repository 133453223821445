
import { defineComponent, ref, reactive } from "vue";
import CloudFun, { Operator } from "@cloudfun/core";
import { helper as $h } from "@/utils/helper";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});

    const gridOptions: GridOptions = {
      title: "優惠券",
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: true,
      stripe: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        { field: "CreatedTime", title: "發放日期", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD') },
        { field: "Number", title: "編號", showHeaderOverflow: true, showOverflow: true, sortable: true },
        {
          field: "Type",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.CouponType).find(e => e.Value === cellValue)?.Name : undefined
        },
        {
          field: "DiscountType",
          title: "折扣方式",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.CouponDiscountType).find(e => e.Value === cellValue)?.Name : undefined
        },
        { field: "StartTime", title: "開始時間", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD HH:mm:ss') },
        { field: "EndTime", title: "結束時間", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD HH:mm:ss') },
        { field: "UseTime", title: "使用時間", showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => $h.formatDate(cellValue, 'YYYY/MM/DD HH:mm:ss') },
        // {
        //   field: "UseTime", title: "狀態", showHeaderOverflow: true, showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? "已使用" : ""
        // }
      ],
      rowClassName(params) {
        const now = new Date().getTime();
        return params.row.UseTime ||
          (params.row.EndTime && new Date(params.row.EndTime).getTime() < now) ||
          (params.row.StartTime && new Date(params.row.StartTime).getTime() > now)
          ? "bg-gray-300"
          : ""
      },
      promises: {
        query: model
          ? (params) => {
            // params.condition!.and("IsReleaseNow", Operator.Equal);
            if (params?.sortings?.length === 0) {
              params.sortings.push({ column: 'CreatedTime', order: 1 });
            }
            return model.dispatch('coupon/query', params);
          }
          : undefined,
        queryAll: model ? () => model.dispatch('coupon/query') : undefined,
        save: model
          ? (params) => model.dispatch('coupon/save', params)
          : undefined
      },
      modalConfig: { width: "80%", height: "60%", showFooter: true },
    };
    const selectedRows = ref<any[]>([]);
    const formOptions: VxeFormProps = {
      items: [
        { field: "Name", title: "名稱", span: 12, itemRender: { name: "$input", props: { placeholder: "請輸入文字" } } },
        { field: "MemberId", title: "會員", span: 12, slots: { default: "column-member-id" } },
        { field: "Type", title: "類型", span: 12, itemRender: { name: "$select", props: { placeholder: "類型" }, options: model ? Object.values(model.enums.CouponType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "DiscountType", title: "折扣方式", span: 12, itemRender: { name: "$select", props: { placeholder: "類型" }, options: model ? Object.values(model.enums.CouponDiscountType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: "StartTime", title: "開始時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "請輸入日期" } } },
        { field: "EndTime", title: "結束時間", span: 12, itemRender: { name: "$input", props: { type: "datetime", placeholder: "請輸入日期" } } },
        { field: "Discount", title: "折扣", span: 12, slots: { default: "column-discount" } },
        { field: "Quantity", title: "發行張數", span: 12, slots: { default: "column-quantity" } },
      ],
      rules: {
        Name: [{ required: true }],
        Discount: [{
          required: true,
          validator: (params) => {
            if ((params.data.DiscountType === 1 && !params.data.DisplayDiscount) || (params.data.DiscountType === 0 && !params.data.DiscountAmount)) {
              return new Error();
            }
          },
        }],
        Quantity: [{
          required: false,
          validator: (params) => {
            if (!params.data.Id && !params.data.Quantity) {
              return new Error();
            }
          },
        }]
      }
    };

    const memberSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'value',
      placeholder: '選擇會員',
      textField: 'label',
      valueField: 'value',
      columns: [
        { field: 'label', title: '姓名', showHeaderOverflow: true, showOverflow: true, sortable: true }
      ],
      promises: {
        find: (value) => new Promise(resolve => {
          model!.dispatch("member/find", value).then(res => resolve({ label: res.Person.Name, value: res.Id }));
        }),
        query: (params) => model!.dispatch("member/options", params) // eslint-disable-line
      },
    }
    const sendCoupon = async(row: any) => {
      const sender = (await model!.dispatch("coupon/send", selectedRows.value));
      console.log(sender);
    }
    const onCheckboxChange = async({ checked, records, reserves, row }: any) => {
      if (checked) {
        selectedRows.value.push(row.Id);
      } else {
        selectedRows.value = selectedRows.value.filter((i:any) => i !== row.Id);
      }
    }

    const onCheckboxAll = async({ checked, records, reserves }: any) => {
      if (checked) {
        for (const record of records) {
          const exist = selectedRows.value.find((e) => e === record.Id);
          if (!exist) selectedRows.value.push(record.Id);
        }
      } else {
        // const data = grid.value.getData();
        // for (const record of data) {
        //   selectedRows.value = selectedRows.value.filter((i:any) => i != record.Id);
        // }
      }
      // console.log(selectedRows.value)
    }

    return {
      grid,
      gridOptions,
      formOptions,
      memberSelectOptions,
      sendCoupon,
      selectedRows,
      onCheckboxChange,
      onCheckboxAll
    };
  },
  methods: {
    onGridEdit(row: any, callback: any) {
      if (row.Id) {
        row.DisplayDiscount = 100 - row.Discount * 100;
      } else {
        row.Type = 0;
        row.DiscountType = 0;
      }
      callback();
    },
    onFormSubmit(row: any, callback: any) {
      if (row.DiscountType === 1) {
        row.Discount = 0;
      } else {
        row.DiscountAmount = 0;
        row.Discount = (100 - row.DisplayDiscount) / 100;
      }
      callback();
    }
  }
});
